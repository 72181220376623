.social {
  margin-top: auto;
  padding-top: rem(40);

  @media screen and (min-width: $bp--tablet) {
    padding-top: 0;
  }

  &__list {
    display: flex;
    justify-content: space-around;
    list-style: none;
  }

  &__list-item {
    display: flex;
    flex: 1;

    .btn {
      flex: 1;
    }

    span {
      display: none;

      @media screen and (min-width: 544px) {
        display: block;
        padding-left: rem(10);
      }

      @media (min-width: $bp--tablet) and (max-width: rem(960)) {
        display: none;
      }
    }

    & + & {
      margin-left: rem(20);
    }
  }
}
