.about {
  .page-section__inner {
    max-width: rem(1340);
    margin: 0 auto;
  }

  &__img {
    background: linear-gradient(70deg, colour-get(primary) 50%, colour-get(secondary) 50%);
  }

  &__img-clip {
    position: relative;
    padding: rem(20);
  }
}
